import { Link, graphql, useStaticQuery } from "gatsby"

import React from "react"

const traverse = (current, parent, obj) => {
  for (const node in current) {
    if (node === "id" && current[node] === parent) {
      ;(current.children = current.children || []).push(obj)
      obj.parents.splice(obj.parents.indexOf(parent), 1)
    }
    if (current[node] instanceof Object) traverse(current[node], parent, obj)
  }
}

export default () => {
  const lookup = []
  const {
    allDriveFile: { nodes: files },
  } = useStaticQuery(graphql`
    query LinkStructure {
      allDriveFile {
        nodes {
          content {
            id
            kind
            mimeType
            parents
            name
            owners {
              emailAddress
            }
          }
        }
      }
    }
  `)

  const tmp = [...files]
  while (tmp.length > 0) {
    tmp.forEach(({ content }, index) => {
      if (!content.parents) {
        lookup.push(content)
        tmp.splice(index, 1)
        return
      }
      if (content.parents.length === 0) {
        tmp.splice(index, 1)
      }
      content.parents.forEach(parentId => {
        traverse(lookup, parentId, content)
      })
    })
  }

  const [strucure] = lookup
  return <Tree children={strucure.children} />
}

const Tree = ({ children }) => (
  <ul className="navigation">
    {children.map(child => {
      return (
        <>
          <li>
            {child.mimeType === `application/vnd.google-apps.document` ? (
              <Link
                to={`/${child.name
                  .replace(/\W+/g, `-`)
                  .replace(/^[-]+|[-]+$/g, ``)
                  .toLowerCase()}`}
              >
                {child.name}
              </Link>
            ) : (
              child.name
            )}
          </li>
          {child.children && <Tree children={child.children} />}
        </>
      )
    })}
  </ul>
)
